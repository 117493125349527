<script>
import PrintTitleNew from './PrintTitleNew'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'PrintTitleEdit',
  extends: PrintTitleNew,
  methods: {
    getPrintTitle () {
      this.$store.dispatch('printTitle/fetchOne', this.$route.params.id)
        .then(() => {
          this.printTitle = this.$store.getters['printTitle/detail']
          this.oldName = this.printTitle.name
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid || !this.isNameValid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('printTitle/update', this.printTitle)
        .then(() => {
          this.printTitle = this.$store.getters['printTitle/detail']
          if (this.$store.getters['printTitle/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['printTitle/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.getPrintTitle()
  }
}
</script>
